import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";
import { capitalize } from "../../../helpers/capitalize";

// NEW STUFF!!!
async function updateProperty(propertyId, fields) {
  return graphqlClient.mutate({
    mutation: gql`mutation updateProperty($property: propertyInput!)  {
      updateProperty(
        propertyId:"${propertyId}",
        property:$property
      ) {
        property_id
      }
    }`,
    variables: {
      property: fields,
    },
  });
}

async function allocateProperty(mainPropertyId, extraPropertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`mutation assign {
			allocateProperty (
				mainPropertyId:"${mainPropertyId}",
				extraPropertyId:"${extraPropertyId}"
			) {
        property_id
				property {
					id
					property_main_attr {
						parkings
						warehouses
					}
					assigned_properties {
						id
						property_assigned_id
					}
				}
			}
		}`,
  });
  return response.data.allocateProperty;
}

async function deallocateProperty(mainPropertyId, extraPropertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`mutation deallocate {
			deallocateProperty (
				mainPropertyId:"${mainPropertyId}",
				extraPropertyId:"${extraPropertyId}"
			) {
        property_id
				property {
					id
					property_main_attr {
						parkings
						warehouses
					}
					assigned_properties {
						id
						property_assigned_id
					}
				}
			}
		}`,
  });
  return response.data.deallocateProperty;
}

// OLD stuff...
async function getProperyTypes() {
  const response = await graphqlClient.query({
    query: gql`
      {
        property_type {
          id
          name
        }
      }
    `,
  });

  return response.data.property_type;
}

async function getPropertyStatus() {
  const response = await graphqlClient.query({
    query: gql`
      {
        property_status {
          id
          name
        }
      }
    `,
  });

  return response.data.property_status;
}

async function add(projectId, property) {
  const mappedExtraAttributes = property.extras.map((obj) => {
    return { property_attribute: obj.id, value: obj.value.toString() };
  });

  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation new_property($property: property_insert_input!) {
        insert_property_one(object: $property) {
          id
        }
      }
    `,
    variables: {
      property: {
        unit_number: property.unit_number,
        level: property.level,
        rooms: property.rooms,
        bathrooms: property.bathrooms,
        price: property.price,
        property_type_id: property.property_type_id,
        status: property.status,
        unit_type_id: property.unit_type_id ? property.unit_type_id : null,
        living_area: property.living_area,
        balcony_area: property.balcony_area,
        garden_area: property.garden_area,
        terrace_area: property.terrace_area,
        total_area: property.total_area,
        construction_area: property.construction_area,
        property_attribute_values: {
          data: mappedExtraAttributes,
        },
        project_id: projectId,
      },
    },
  });

  return response.data.insert_property_one;
}

async function list(projectId, type = "APARTMENT") {
  const response = await graphqlClient.query({
    query: gql`
    {
		  property(where: {
          project_id: {_eq: "${projectId}"},
          property_type: {id: {_eq:"${type}"} },
          _or:[
            {deleted: { _is_null: true }},
            {deleted: { _eq: false }}
          ]
        },
        order_by: {created_at: desc}) {
		    id
		    property_type {
		      id
		      name
		    }
        status
		    unit_number
		    level
		    total_area
        living_area
        construction_area
		    rooms
		    bathrooms
		    price
        number
        unit_type
        unit_type_id
        balcony_area
          garden_area
          terrace_area
        project {
            id
            name
          }
        property_attribute_values {
          id
          value
          property_attribute
        }
        property_images {
          id
          img_url
          title
          type
          property_image_gallery
        }
        project_unit_type {
          id
          type
          img_url
        }
        property_status {
          id
          name
        }
        total_area
		  }
		}
    `,
  });

  return response.data.property;
}

async function get(propertyId) {
  const response = await graphqlClient.query({
    query: gql`
    {
      property(where: {id: {_eq: "${propertyId}"}}) {
        id
        property_type {
          id
          name
        }
        status
        unit_number
        level
        total_area
        living_area
        rooms
        bathrooms
        price
        unit_type
        unit_type_id
        living_area
        terrace_area
        balcony_area
        garden_area
        living_area
        construction_area
        project {
          id
          name
        }
        property_attribute_values {
          id
          property_attribute
          value
        }
        property_images {
          id
          img_url
          alternative_text
          title
          property_image_gallery
        }
        project_unit_type {
          id
          type
          img_url
        }
        property_status {
          id
          name
        }
        assigned_properties(where: { _or:[
          {deleted: {_eq: false}},
          {deleted: {_is_null: true}}
        ]})  {
          property {
            id
            unit_number
            price
            level
            property_attribute_values {
              value
              property_attribute
            }
            total_area
            property_type_id
          }
        }
      }
    }
    `,
  });

  return response.data ? response.data.property[0] : null;
}

async function updatePropertyExtraAttributes(propertyId, name, value) {
  const response = await graphqlClient.mutate({
    mutation: gql`
			mutation {
				update_property_attribute(
					where: {
						property_attribute:{_eq:"${name}"},
						property_id:{_eq:"${propertyId}"}
					}, _set:{
						value: "${value.toString()}"
					}) {
					affected_rows
				}
			}
    `,
  });

  return response.data.update_property_attribute;
}

async function deleteByPk(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation {
        delete_property_by_pk(id: "${propertyId}") {
          id
        }
      }
    `,
  });

  return response.data.delete_property_by_pk;
}
async function deletePropertyExtraAttributes(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation {
        delete_property_attribute(where:{
          property_id: {
            _eq: "${propertyId}"
          }
        }) {
          affected_rows
        }
      }
    `,
  });
  return response.data.delete_property_attribute;
}

async function deletePropertyPriceLog(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation {
        delete_property_price_log(where: {
          property_id:{
            _eq: "${propertyId}"
          }
        }) {
          affected_rows
        }
      }
    `,
  });
  return response.data;
}

async function getBatchProportiesByUnitCode(
  data,
  projectId,
  propertyAssignedType,
  mainPropertyType
) {
  let queries = [];
  data.forEach(async (element) => {
    const propertyUnitNumber = element.propiedad.toString().trim();
    let propertyAssignedUnitNumber = element.parqueo || element.bodega;
    propertyAssignedUnitNumber = propertyAssignedUnitNumber.toString().trim();
    const item = `Fila_${element.__rowNum__ + 1}: property(where: {
                  _and:{
                    unit_number: {
                      _in:["${propertyUnitNumber}", "${propertyAssignedUnitNumber}"],
                    },
                    _or:[
                      {deleted: {_eq: false}},
                      {deleted: {_is_null: true}}
                    ],
                  }
                  _or: [
                    {status: {_eq: AVAILABLE}},
                    {status: {_eq: BLOCKED}}
                  ],
                  project_id: {
                    _eq: "${projectId}"
                  }
                  property_type_id:{
                    _in:[${mainPropertyType}, ${propertyAssignedType}]
                  }
                }) {
                  id
                  number
                  unit_number
                  property_type_id
                }`;
    queries.push(item);
  });
  const response = await graphqlClient.query({
    query: gql`
			query properties {
        ${queries}
			}
    `,
  });

  return response.data;
}

async function listPropertiesAssigned(projectId, propertyType = "PARKING") {
  const response = await graphqlClient.query({
    query: gql`
    {
      assigned_property (
        where: {
          _or:[
            {deleted: {_eq: false}},
            {deleted: {_is_null: true}}
          ],
          property:{
            project_id:{
              _eq: "${projectId}"
            },
            property_type: {
              id:{
                _eq: "${propertyType}"
              }
            }
          },
          propertyByPropertyId: {
            project_id: {
              _eq: "${projectId}"
            }
          }
        }
      ){
        property_assigned_id
        deleted
        property {
          id
          unit_number
          price
          status
          property_type {
            id
            name
          }
        }
        property_id
        propertyByPropertyId {
          id
          unit_number
          price
          status
          property_type {
            id
            name
          }
        }
      }
    }
    `,
  });

  return response.data.assigned_property;
}

async function getWarehouses(propertyId) {
  const response = await graphqlClient.query({
    query: gql`
    {
      property(where: {
        project_id:{
          _eq:"${propertyId}"
        }
        property_type:{
          id:{
            _eq:"WAREHOUSE"
          }
        },
        _or:[
          {deleted: { _is_null: true }},
          {deleted: { _eq: false }}
        ]
      }, order_by: {created_at: desc}) {
        id
        property_type {
          id
          name
        }
        status
        unit_number
        level
        rooms
        bathrooms
        price
        balcony_area
          garden_area
          terrace_area
        project {
            id
            name
          }
        property_attribute_values {
          id
          property_attribute
          value
        }
        property_images {
          id
          img_url
          alternative_text
          title
          property_image_gallery
        }
        property_status {
          id
          name
        }
        total_area
      }
    }
    `,
  });
  return response.data.property;
}

async function getPropertyAttributeValue(attributeType = null) {
  const response = await graphqlClient.query({
    query: gql`
      query propertyAttribure($attributeType: String) {
        property_attribute_value(where: { id: { _eq: $attributeType } }) {
          id
          name
          values
        }
      }
    `,
    variables: {
      attributeType,
    },
  });
  return response.data.property_attribute_value;
}

export const checkFinancingValues = async (propertyId) =>
  await graphqlClient.query({
    query: gql`
      query checkValues($propertyId: uuid!) {
        property(where: { id: { _eq: $propertyId } }) {
          id
          project {
            id
            min_downpayment_percent
            max_downpayment_installments
            financing_types {
              id
              monthly_insurance_rate
              interest_rate
              bank {
                id
                name
              }
            }
            project_images(where: { project_image_gallery: { _eq: COVER } }) {
              id
              img_url
            }
          }
        }
      }
    `,
    variables: {
      propertyId,
    },
  });

export const getPropertyByPk = async (id) =>
  await graphqlClient.query({
    query: gql`
      query getPropertyByPk($id: uuid!) {
        property_by_pk(id: $id) {
          id
          property_type {
            id
            name
          }
          status
          unit_number
          level
          total_area
          living_area
          construction_area
          rooms
          bathrooms
          price
          unit_type
          unit_type_id
          balcony_area
          garden_area
          terrace_area
          project {
            id
            name
          }
          property_attribute_values {
            id
            property_attribute
            value
          }
          property_images {
            id
            img_url
            alternative_text
            title
            property_image_gallery
          }
          project_unit_type {
            id
            type
            img_url
            project_unit_type_images {
              id
              image_url
            }
          }
          assigned_properties(
            where: {
              _or: [
                { deleted: { _neq: true } }
                { deleted: { _is_null: true } }
              ]
            }
          ) {
            property {
              id
              unit_number
              property_type_id
              price
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
  });

async function getPropertyDistinctTypes(projectId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        property (distinct_on:[unit_type], where: {
          project_id: {
            _eq: "${projectId}"
          },
          unit_type: {
            _is_null: false
          }
        }, order_by:{
          unit_type: asc
        }){
          unit_type
        }
      }
    `,
  });
  return response.data.property;
}

async function updatePropertyAttributeFields(propertyId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updatePropertyAttr(
        $fields: property_attribute_set_input
        $propertyId: uuid
      ) {
        update_property_attribute(
          where: { property_id: { _eq: $propertyId } }
          _set: $fields
        ) {
          affected_rows
          returning {
            id
            deleted
            value
          }
        }
      }
    `,
    variables: {
      propertyId,
      fields,
    },
  });

  return response.data.update_property_attribute;
}

async function updatePropertyPriceLogFields(propertyId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updPropertyPriceLog(
        $fields: property_price_log_set_input
        $propertyId: uuid
      ) {
        update_property_price_log(
          where: { property_id: { _eq: $propertyId } }
          _set: $fields
        ) {
          affected_rows
          returning {
            id
            deleted
            old_price
            new_price
          }
        }
      }
    `,
    variables: {
      propertyId,
      fields,
    },
  });

  return response.data.update_property_price_log;
}

async function updateAssignedByProperty(propertyId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updPropertyAssigned(
        $fields: assigned_property_set_input
        $propertyId: uuid
      ) {
        update_assigned_property(
          where: { property_id: { _eq: $propertyId } }
          _set: $fields
        ) {
          affected_rows
          returning {
            id
            deleted
          }
        }
      }
    `,
    variables: {
      propertyId,
      fields,
    },
  });
  return response.data.update_assigned_property;
}

async function updatePropertyAssigned(propertyId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updPropertyAssigned(
        $fields: assigned_property_set_input
        $propertyId: uuid
      ) {
        update_assigned_property(
          where: { property_assigned_id: { _eq: $propertyId } }
          _set: $fields
        ) {
          affected_rows
          returning {
            id
            deleted
          }
        }
      }
    `,
    variables: {
      propertyId,
      fields,
    },
  });

  return response.data.update_assigned_property;
}

async function searchProperty(projectId, notPropertyType, query) {
  const response = await graphqlClient.query({
    query: gql`
      query searchProperty($projectId: uuid!, $notPropertyType: [property_type_enum!]) {
          property(where:{
            project_id:{
              _eq: $projectId
            },
            property_type_id: {
              _nin: $notPropertyType
            },
            unit_number: {
              _ilike: "%${query}%"
            },
            _or: [
                    {status: {_eq: AVAILABLE}},
                    {status: {_eq: BLOCKED}}
            ],
           _and: {
              _or:[
                {deleted: { _is_null: true }},
                {deleted: { _eq: false }}
              ]
            }
          }) {
            id
            unit_number
            total_area
            living_area
            balcony_area
            construction_area
            price
            project {
              id
              name
            }
          }
      }
    `,
    variables: {
      projectId,
      notPropertyType,
    },
  });
  return response.data.property;
}

async function updatePropertyByIn(propertiesId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updateproperyByIdIn(
        $propertiesId: [uuid!]
        $fields: property_set_input!
      ) {
        update_property(where: { id: { _in: $propertiesId } }, _set: $fields) {
          affected_rows
        }
      }
    `,
    variables: {
      propertiesId,
      fields,
    },
  });

  return response.data.update_property;
}

async function getBatchPropertyStatuses(data) {
  let queries = [];
  data.forEach(async (element) => {
    const propertyStatus = capitalize(element.estado.toLowerCase().trim());

    const item = `Fila_${element.__rowNum__ + 1}: property_status(where: {
                    name: {
                      _eq: "${propertyStatus}"
                    }
                }) {
                  id
                  name
                }`;
    queries.push(item);
  });

  const response = await graphqlClient.query({
    query: gql`
			query property_estatuses {
        ${queries}
			}
    `,
  });

  return response.data;
}

async function deleteProperties(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation deleteProperty($propertyId: String!) {
        deleteProperty(propertyId: $propertyId) {
          property_id
        }
      }
    `,
    variables: {
      propertyId,
    },
  });

  return response.data.update_property;
}

async function addPropertyExtraAttributes(propertyAttributes) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addPropertyExtra($input: property_attribute_insert_input!) {
        insert_property_attribute_one(object: $input) {
          id
        }
      }
    `,
    variables: {
      input: propertyAttributes,
    },
  });

  return response.data.insert_property_attribute_one;
}

async function listPropertyAvailable(projectId, type = "APARTMENT") {
  const response = await graphqlClient.query({
    query: gql`
    {
		  property(where: {
          project_id: {_eq: "${projectId}"},
          status: {_eq: AVAILABLE}
          property_type: {id: {_eq:"${type}"} },
          _not:{assignedPropertiesByPropertyAssignedId: {id: {_is_null: false}}},
          _or:[
            {deleted: { _is_null: true }},
            {deleted: { _eq: false }}
          ],
        },
        order_by: {created_at: desc}) {
          id
          unit_number
        }
    }
    `,
  });

  return response.data.property;
}

async function listPropertyAsignedByPk(propertyId, type = "APARTMENT") {
  const response = await graphqlClient.query({
    query: gql`
    {
		  property_by_pk(id: "${propertyId}") {
        assigned_properties(where: {property: {property_type_id: {_eq: ${type}}}, deleted: {_eq: false}}) {
          property {
            id
            unit_number
            price
            level
            property_attribute_values {
              value
              property_attribute
            }
            total_area
          }
        }
      }
    }
    `,
  });
  return response.data.property_by_pk.assigned_properties;
}

async function totalPropertyList(projectId, type = null) {
  const boolPropType = {};
  boolPropType["id"] = type
    ? { _eq: type }
    : { _nin: ["PARKING", "WAREHOUSE"] };

  const response = await graphqlClient.query({
    query: gql`
      query propertyAggregate(
        $projectId: uuid!
        $boolPropType: property_type_bool_exp
      ) {
        property_aggregate(
          where: {
            project_id: { _eq: $projectId }
            property_type: $boolPropType
            _or: [{ deleted: { _is_null: true } }, { deleted: { _eq: false } }]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      projectId,
      boolPropType,
    },
  });

  return response.data.property_aggregate.aggregate.count;
}

async function totalAssignedList(projectId, propertyType = "PARKING") {
  const response = await graphqlClient.query({
    query: gql`
    {
      assigned_property_aggregate(where: {_or: [{deleted: {_eq: false}}, {deleted: {_is_null: true}}], property: {project_id: {_eq: "${projectId}"}, property_type: {id: {_eq: "${propertyType}"}}}, propertyByPropertyId: {project_id: {_eq: "${projectId}"}}}) {
        aggregate {
          count
        }
      }
    }`,
  });
  return response.data.assigned_property_aggregate.aggregate.count;
}

async function createMainProperty(projectId, property) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation createProperty($property: CreatePropertyInput!) {
        createProperty(property: $property) {
          property {
            id
          }
        }
      }
    `,
    variables: {
      property: {
        type: property.type,
        status: property.status,
        unitNumber: property.unitNumber,
        level: property.level,
        tower: property.tower,
        view: property.view,
        bedrooms: property.bedrooms,
        bathrooms: property.bathrooms,
        price: property.price,
        unitType: property.unitType,
        balcony: property.balcony,
        kitchenFurniture: property.kitchenFurniture,
        closet: property.closet,
        laundry: property.laundry,
        garden: property.garden,
        homeAppliances: property.homeAppliances,
        livingArea: property.livingArea,
        terraceArea: property.terraceArea,
        balconyArea: property.balconyArea,
        gardenArea: property.gardenArea,
        constructionArea: property.constructionArea,
        totalArea: property.totalArea,
        projectId: projectId,
      },
    },
  });

  return response.data.createProperty.property || null;
}

async function createExtraProperty(projectId, property) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation createProperty($property: CreatePropertyInput!) {
        createProperty(property: $property) {
          property {
            id
          }
        }
      }
    `,
    variables: {
      property: {
        type: property.type,
        status: property.status,
        unitNumber: property.unitNumber,
        level: property.level,
        tower: property.tower,
        price: property.price,
        totalArea: property.totalArea,
        projectId: projectId,
        parkingType: property.parkingType || null,
      },
    },
  });

  return response.data.createProperty.property || null;
}

async function blockProperty(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation blockProperty($propertyId: uuid!) {
        blockProperty(id: $propertyId) {
          property {
            id
          }
        }
      }
    `,
    variables: {
      propertyId,
    },
  });

  return response.data.blockProperty || null;
}

async function enableProperty(propertyId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation enableProperty($propertyId: uuid!) {
        enableProperty(id: $propertyId) {
          property {
            id
          }
        }
      }
    `,
    variables: {
      propertyId,
    },
  });

  return response.data.enableProperty || null;
}

async function parkingTypes(projectId) {
  const response = await graphqlClient.query({
    query: gql`
    query {
      property(where:{
        property_type_id: {
          _eq: PARKING
        },
        project_id:{
          _eq: "${projectId}"
        }
      }) {
        id
        property_attribute_values(where: {
          property_attribute: {
            _eq:"PARKING_TYPE"
          }
        }) {
          id
          value
        }
      }
    }`,
  });

  if (!response.data.property.length) {
    return [];
  }

  const values = response.data.property.map(
    (item) => item.property_attribute_values[0]
  );

  return Array.from(new Set(values.map((item) => item.value)));
}

export default {
  getProperyTypes,
  getPropertyStatus,
  add,
  list,
  get,
  updatePropertyExtraAttributes,
  getBatchProportiesByUnitCode,
  listPropertiesAssigned,
  getWarehouses,
  deleteByPk,
  deletePropertyExtraAttributes,
  deletePropertyPriceLog,
  getPropertyAttributeValue,
  getPropertyByPk,
  getPropertyDistinctTypes,
  updatePropertyAttributeFields,
  updatePropertyPriceLogFields,
  updateAssignedByProperty,
  updatePropertyAssigned,
  checkFinancingValues,
  searchProperty,
  updatePropertyByIn,
  getBatchPropertyStatuses,
  deleteProperties,
  addPropertyExtraAttributes,
  listPropertyAvailable,
  listPropertyAsignedByPk,
  totalPropertyList,
  totalAssignedList,
  // new mutations
  updateProperty,
  allocateProperty,
  deallocateProperty,
  createMainProperty,
  createExtraProperty,
  blockProperty,
  enableProperty,
  parkingTypes,
};
